import Server from "./Server";
import {Servers} from "./WallOfHonour";
import React, {useContext, useEffect, useState} from "react";
import apiContext from "../ApiContext";

function Home() {
    const [servers, setServers] = useState([]);
    const [totalServers, setTotalServers] = useState(0);
    const [totalOnline, setTotalOnline] = useState(0);
    const [totalPlayers, setTotalPlayers] = useState(0);
    const ApiContext = useContext(apiContext);

    useEffect(() => {
        ApiContext.getActiveServers().then(function(response) {
            setServers(response.data.servers);
            setTotalServers(response.data.servers.length);
            setTotalOnline(response.data.servers.reduce((total, server) => total + server.online, 0));
            setTotalPlayers(response.data.servers.reduce((total, server) => total + server.players, 0));
        })
    }, [ApiContext]);

    return (
        <>
            <div className="blokas" style={{textAlign: 'center'}}>
                <table style={{padding: '3px 0px 3px 0px'}}>
                    <tbody>
                    <tr>
                        <td id="sub_line"/>
                        <td className="title_text"><span>ŽAIDIMO PASAULIAI</span></td>
                        <td id="sub_line"/>
                    </tr>
                    </tbody>
                </table>
                <table style={{textAlign: 'left'}}>
                    <tbody>
                    {servers.map((server) => <Server key={server.url}
                                                     url={server.url}
                                                     players={server.players}
                                                     speed={server.speed}
                                                     online={server.online}
                                                     startDate={server.start_date}/>)}
                    </tbody>
                </table>
                <div className="line">&nbsp;</div>
            </div>
            <div className="blokas" style={{textAlign: 'left'}}>
                <table style={{padding: '3px 0px 3px 0px'}}>
                    <tbody>
                    <tr>
                        <td id="sub_line"/>
                        <td className="title_text"><span>STATISTIKA</span></td>
                        <td id="sub_line"/>
                    </tr>
                    </tbody>
                </table>
                <ul>
                    <li>Aktyvių pasaulių: <strong>{totalServers}</strong></li>
                    <li>Šiuo metu žaidžia: <strong>{totalOnline}</strong></li>
                    <li>Viso žaidėjų: <strong>{totalPlayers}</strong></li>
                </ul>
            </div>
            <Servers/>
        </>
    )
}

export default Home;