import React from 'react';
import axios from 'axios';
import ApiContext from "./ApiContext";

function ApiClient(props)
{
    const baseUrl = 'https://api.tinwap.lt/';

    const getActiveServers = () => {
        return axios.get(baseUrl + 'servers');
    };

    const getPastServers = (start, limit) => {
        return axios.get(baseUrl + `server-history?start=${start}&limit=${limit}`);
    };

    const getServerData = (serverId) => {
        return axios.get(baseUrl + `server-history/${serverId}`);
    };

    return (
        <ApiContext.Provider value={{getActiveServers, getPastServers, getServerData}}>
            {props.children}
        </ApiContext.Provider>
    )
}

export default ApiClient;