import React, {useState, useEffect, useContext} from "react";
import apiContext from "../ApiContext";
import {useParams, Link} from 'react-router-dom';
import Pagination from "./Pagination";
import TopCategory from "./ServerTops/TopCategory";
import BestPlayer from "./ServerTops/BestPlayer";
import Informator from "./Informator";

function WallOfHonour() {
    let {serverId} = useParams();
    const [loading, setLoading] = useState(true);
    const [serverData, setServerData] = useState({});
    const ApiContext = useContext(apiContext);

    useEffect(() => {
        ApiContext.getServerData(serverId).then(function (res) {
            if (res.status === 200) {
                setServerData(res.data);
                setLoading(false);
            }
        }).catch(function (err) {
        });
    }, [ApiContext, serverId]);

    return <div>
        {!loading &&
        <>
            <Informator>
                Labiausiai pasižymėję x{serverData.server.speed} serverio ({serverData.server.start_date.split(' ')[0] } - {serverData.server.end_date.split(' ')[0]}) žaidėjai ir aljansai.<br/>Dėkojame visiems žaidusiems šiame serveryje!
            </Informator>
            <TopCategory data={serverData.pop} title="DIDŽIAUSI ŽAIDĖJAI"/>
            <TopCategory data={serverData.off} title="GERIAUSI PUOLĖJAI"/>
            <TopCategory data={serverData.deff} title="GERIAUSIAI BESIGINANTYS"/>
            <BestPlayer data={serverData.best} />
        </>}
    </div>
}

function Servers() {
    const ApiContext = useContext(apiContext);
    const [servers, setServers] = useState([]);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [start, setStart] = useState(0);
    const limit = 8;

    useEffect(() => {
        ApiContext.getPastServers(start, limit).then(function (res) {
            setServers(res.data.servers);
            setPages(Math.ceil(res.data.total / limit));
        }).catch(function (err) {

        });
    }, [start, ApiContext]);

    const goToPage = (newPage) => {
        setPage(newPage);
        setStart(newPage * limit - limit);
    };

    return (
        <div className="blokas" style={{textAlign: 'left'}}>
            <table style={{padding: '3px 0px 3px 0px'}}>
                <tbody>
                <tr>
                    <td id="sub_line"/>
                    <td className="title_text"><span>GARBĖS LENTA</span></td>
                    <td id="sub_line"/>
                </tr>
                </tbody>
            </table>
            <table>
                <tbody>
                {servers.map((server) =>
                    <tr key={server.id}>
                        <td><Link to={`/server/${server.id}`}>{server.name}</Link></td>
                        <td><small>({server.start_date.split(' ')[0]} - {server.end_date.split(' ')[0]})</small></td>
                    </tr>
                )}
                </tbody>
            </table>
            <Pagination total={pages}
                        page={page}
                        goToPage={goToPage}/>
        </div>
    )
}

export {
    WallOfHonour,
    Servers
};
