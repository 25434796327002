import React from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import vk from './images/vk3.gif';
import fb from './images/fb3.gif';
import logo from './images/logo-big.gif'
import './App.css';
import Home from "./components/Home";
import {WallOfHonour} from "./components/WallOfHonour";
import Obfuscate from 'react-obfuscate';
import Rules from './components/Rules'

function App() {
    return (
        <Router>
            <div className="wrapper" id="virsus" style={{marginTop: '10px'}}>
                <div className="big_logo" style={{textAlign: 'center'}}>
                    <Link to="/"><img src={logo} alt="Tinwap" className="logo"/></Link>
                </div>
                <div className="big_text" style={{padding: '14px 5px', lineHeight: '150%'}}><strong>TINWAP</strong> -
                    naršyklinis strateginis žaidimas skirtas mobiliesiems telefonams. Čia pradėsite kurti savo imperiją,
                    subursite galingą kariuomenę ir kovosite su savo sajungininkais už pasaulini viešpatavimą bei šlovę!
                </div>
                <div className="middle">
                    <Switch>
                        <Route path="/server/:serverId">
                            <WallOfHonour />
                        </Route>
                        <Route path="/rules">
                            <Rules />
                        </Route>
                        <Route path="/">
                            <Home />
                        </Route>
                    </Switch>
                </div>
                <div className="bottom" style={{textAlign: 'left'}}>
                    <table className="navigacija">
                        <tbody>
                        <tr>
                            <td style={{width: '25%', verticalAlign: 'middle'}}>
                                <a href="https://facebook.com/tinwap"><img src={fb} alt="facebook"/></a>
                            </td>
                            <td style={{width: '25%', verticalAlign: 'middle', borderLeft: '1px dashed #dec473'}}>
                                <table style={{margin: '0 auto'}}>
                                    <tbody>
                                        <tr>
                                            <td><Obfuscate email="info@tinwap.lt" /></td>
                                            <td><Obfuscate tel="+37069000296" /></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">© Mindaugas Veblauskas</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Router>
    );
}

export default App;
