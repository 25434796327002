import React from "react";

export default function() {
    return <div style={{textAlign: "left", padding: "20px"}}>
        <strong>Paskyra</strong>
        <ul>
            <li>Kiekvienas žaidėjas turi teisę sukurti ir valdyti tik vieną paskyrą kiekviename žaidimo serveryje. Tinwap komanda taip pat bet kuriuo metu gali pakeisti paskyros vardą, jei jis prieštarauja taisyklėms.</li>
            <li>Paskyros savininku pripažįstamas asmuo, kuriam priklauso el. pašto adresas, nurodytas nustatymuose. Paskyros nustatymuose nurodyto el. pašto adreso savininkas pripažįstamas paskyros savininku, nepaisant jokių asmeninių arba aljanso susitarimų. Norėdami pakeisti paskyros registracijos el. pašto adresą, galite tai padaryti vartotojo nustatymuose. Paskyros savininkas atsako už visus veiksmus, atliktus jam priklausančios paskyros vardu. Iškilus abejonėms, ar paskyrą valdo teisėtas savininkas administracija turi teisę pareikalauti atsiųsti patvirtinimą iš nustatymuose nurodyto el. pašto adreso.</li>
            <li>El. pašto adreso pakeitimas nustatymuose į priklausanti kitam asmeniui adresą laikomas paskyros perleidimu šio asmens nuosavybėn.</li>
            <li>Slaptažodžio atskleidimas kitam asmeniui yra draudžiamas, (išskyrus partnerystės santykius), kai du arba daugiau asmenų kartu valdo vieną bendrą paskyrą, nesijungdami prie jokių kitų paskyrų tame pačiame serveryje. Jei paskyros savininkas nori nustoti žaisti su viena paskyra ir pradėti žaisti su kita, pirmoji paskyra turi būti perleista kitam asmeniui PRIEŠ TAI, kai gaunama kita arba sukuriama nauja paskyra. Jei nesantis paskyros savininku žaidėjas (buvęs partneris) nori pradėti žaisti su kita paskyra, tuomet jis turi įsitikinti, kad slaptažodis yra pakeistas ir jis nebegali prisijungti prie senos paskyros PRIEŠ TAI, kai gaus arba susikurs naują paskyrą.</li>
            <li>Paskyroms, susijusioms pavadavimo santykiais arba kitokiu būdu, draudžiama nustatyti vienodus slaptažodžius. TINWAP  neatlygina paskyros savininkui jokios žalos, patirtos dėl kito asmens, kuriam buvo patikėtas slaptažodis, veiksmų. TINWAP komandos nariai niekada neklausia vartotojo slaptažodžio.</li>
            <li>Laikinų arba vienkartinių el. pašto adresų naudojimas yra draudžiamas ir gali sukelti panaikinimą nedelsiant.</li>
        </ul>

        <strong>Pagalbinės priemonės</strong>
        <ul>
            <li>TINWAP turi būti žaidžiamas įprastos naršyklės pagalba, be pagalbinių modifikacijų. Palaikomos naršyklės: Google Chrome, Safari, Internet Explorer 7 arba naujesnė, Firefox 4 arba naujesnė ir Opera 12 arba naujesnė, išskyrus alfa ir beta versijas. Skriptų ir botų naudojimasis, siekiant automatizuoti žaidimo veiksmus, yra draudžiamas. </li>
            <li>Proxy serverių ir kitų analogiškų prisijungimo priemonių naudojimas nėra tiesiogiai uždraustas, tačiau yra nesveikintinas. Bandant naudoti proxy arba kitas ip adresą keičiančius įrankius, galimybė žaisti gali būti ribojama, ir TINWAP neatitaisys jos.</li>
        </ul>

        <strong>Programos klaidos</strong>
        <ul>
            <li>Draudžiama bandyti pasinaudoti žaidime aptiktomis klaidomis savo paskyros naudai arba svetimoms paskyroms trukdyti. Piktybiškas pasinaudojimas klaidomis yra baustinas. Aptikus klaidą, vartotojas gali pranešti apie ją žaidimo pagalbos tarnybai (Support).</li>
        </ul>

        <strong>Pinigų ir žaidimo vertybių transakcijos</strong>
        <ul>
            <li>Draudžiama parduoti ir pirkti už tikrus pinigus žaidimo paskyras, pajėgas, miestus, resursus, paslaugas arba kitas su TINWAP susijusias vertybes. Draudžiama parduoti arba netiesiogiai perleisti (dovanos pavidalu) TINWAP paskyras aukcionuose arba kituose pinigines perlaidas numatančiuose tinklapiuose.</li>
            <li>Bet kurie bandymai manipuliuoti arba piktnaudžiauti žaidimo aukcionų sistema arba perleisti žaidimo vertybes iš vienos paskyros į kitą yra baustini.</li>
        </ul>

        <strong>Elgesio normos</strong>
        <ul>
            <li>Žaidėjai turi pagarbiai bendrauti vieni su kitais. </li>
            <li>Pagalba ir aptarnavimas užtikrinami tik oficialiomis serverio kalbomis.</li>
            <li>Žaidimo taisyklėms neatitinkančius žaidėjų aprašymus, miestų pavadinimus, karžygių vardus Multihanteriai gali keisti be išankstinio perspėjimo. Aljansai su taisyklių neatitinkančiais pavadinimais gali būti nedelsiant naikinami; analogiškos priemonės gali būti taikomos aljansų aprašymams, viešiems forumams ir kitokiems viešai skelbiamiems užrašams užsienio kalbomis be vertimo į oficialią serverio kalbą (kalbas). </li>
            <li>Griežtai draudžiama: Įžeisti, šmeižti, žeminti dėl lyties arba tautybės, vartoti keiksmažodžius; reikšti neigiamą požiūrį į religijas, tautybes, lytį, amžių, kelti seksualinės orientacijos tematiką; grąsinti susidoroti ar keršyti realiame gyvenime; melagingai prisistatyti TINWAP komandos nariais arba pareigūnais.</li>
            <li>Žaidimas turi būti laisvas nuo tikrojo pasaulio politikos.</li>
            <li>Draudžiama skelbti arba siūlyti peržiūrėti mažamečiams vaikams netinkamo turinio medžiagas. </li>
            <li>Viešai skelbti žaidimo asmens žaidimo žinutes galima tik gavus abipusį leidimą. Viešai skelbti žaidimo žinučių arba el. pašto susirašinėjimą su TINWAP komandos nariais yra draudžiama, jei tam nesuteiktas aiškus leidimas.</li>
            <li>Reklama bet kuriuo būdu yra draudžiama.</li>
        </ul>

        <strong>Bausmė</strong>
        <ul>
            <li>Žaidimo taisyklių arba Projekto nuostatų nepaisymo atvejais paskyra blokuojama, ir administracijos nuožiūra gali būti atblokuota su bauda, priklausomai nuo įvykusio pažeidimo. TINWAP neatlygina nuostolių, patirtų blokavimo metu, įskaitant Pliuso režimą ir auksinius. Visi žaidėjai vertinami ir baudžiami vienodai, nepriklausomai nuo to, ar jie naudojasi mokamomis paslaugomis. Bandymai manipuliuoti TINWAP taisyklių laikymosi priežiūros sistema, žaidimo algoritmais arba kitais TINWAP administravimo aspektais, siekiant gauti sau naudos arba pakenkti kitiems, yra neleistini.</li>
            <li>Aptarti blokavimą galima tik su savo serverio Multihanteriais žaidimo žinučių pagalba. Jei žaidėjas nesutinka su Multihanterio sprendimu, jis gali apskųsti jį, nusiųsdamas argumentuotą laišką savo šalies Tinwap padalinio vadovui el.paštu info@tinwap.lt. Blokavimai, panaikinimai ir jų pasekmės nėra viešai aptartini. Bet kuri informacija dėl veiksmų, kurių buvo imtasi arba kurių numatoma imtis paskyros atžvilgiu, suteikiama tik paskyros savininkui. Tai reiškia, kad TINWAP komandos nariai gali atsakyti tik į tiesiogiai paskyros savininko nusiųstas žaidimo žinutes į arba laiškus, gautus el. paštu iš savininkui priklausančio adreso.</li>
            <li>o	Jei vienas asmuo jungiasi prie kelių paskyrų viename serveryje (Multiaccounts), tokios paskyros, ypač esančios greitajame serveryje ir/arba turinčios iki 100 gyventojų, gali būti naikinamos be blokavimo arba perspėjimo.</li>
        </ul>

        <strong>Taisyklių pakeitimai ir pataisymai</strong>
        <ul>
            <li>TINWAP pasilieka sau teisę pakeisti šias taisykles bet kuriuo metu. Jeigu vienas šių taisyklių straipsnių nustoja galioti, visi likę straipsniai lieka galioti. TINWAP padalinių vadovai pakeis nustojusį galioti straipsnį kiek įmanoma greičiau.
            </li>
        </ul>
    </div>
}