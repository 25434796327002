import React from "react";

function Server(props)
{
    return (
        <tr>
            <td>
                <ul>
                    <li>Greitis: x{props.speed}</li>
                    <li>Startavo: {props.startDate}</li>
                    <li>Žaidėjų: {props.players}</li>
                    <li>Prisijungusių: {props.online}</li>
                    <li><a href={props.url}
                           className="small_button"
                           style={{marginTop: '5px', textAlign: 'center'}}>
                        <strong>Žaisti</strong></a>
                    </li>
                </ul>
            </td>
        </tr>
    )
}

export default Server;